import * as yup from "yup";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import { Formik } from "formik";
import { SUBSCRIPTION } from '../../../request/api';


function SubscribeForm() {
  let [state, setState] = useState({
    status_message: {
      type: "success",
      message: "",
    },
  });
  let { status_message } = state;


  const subscribe_email_yup = yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required");


  const subscribe_init_value = {
    email: "",
  }

  const subscribe_validation_schema = yup.object({
    email: subscribe_email_yup,
  });
  return (
    <div className="subscribeForm container-fluid">
      <div className="row subscribeForm__row">
        <div className="col-md-5 col-12">
          <div className="subscribeForm__title text-white">
            <h4>Complete this form to subscribe</h4>
            <h5>You will be able to contribute to the platform by sharing news, documents, events.</h5>
          </div>
        </div>

        <div className="col-md-5 col-12">
          <div className="subscribeForm__form">
            <Formik
              enableReinitialize={true}
              initialValues={subscribe_init_value}
              validationSchema={subscribe_validation_schema}

              onSubmit={async (values, actions) => {
                let res = await SUBSCRIPTION(values);
                if (res.data) {
                  setState({
                    ...state,

                    status_message: {
                      type: "success",
                      message: res.data.message,

                    },
                  });
                } else {
                  let { message, errors } = res.response.data;


                  for (const property in errors) {
                    console.log(`${property}: ${errors[property]}`);
                    // message = message + ` ${property}: ${errors[property]}`
                    message = message + ` ${errors[property]}`;
                  }

                  setState({
                    ...state,

                    status_message: {
                      type: "error",
                      message,
                    },
                  });
                }
              }}
            >

              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <h1 className="text-white subscribeForm__formTitle">Subscribe</h1>

                  <div className="form-floating subscribeForm__formFloating">
                    <TextField
                      fullWidth
                      label="Enter Your Email"
                      id="email"
                      variant="filled"
                      sx={{ background: "#fff" }}
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      error={
                        props.touched.email && Boolean(props.errors.email)
                      }
                      helperText={props.touched.email && props.errors.email}

                    />
                  </div>

                  {/*<div className="form-floating subscribeForm__formFloating">
                <textarea
                  className="form-control"
                  id="floatingInput"
                  rows="5"
                  placeholder="Let us know your interests about our platform"
                />
                <label for="floatingInput">
                  Let us know your interests about our platform
                </label>
              </div>*/}

                  {/*<div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                />
                <label for="floatingPassword">Password</label>
              </div>

              <div className="checkbox mb-3">
                <label>
                  <input type="checkbox" value="remember-me" /> Remember me
                </label>
              </div>*/}
                  <button
                    className="w-100 btn btn-lg btn-primary subscribeForm__submitBtn"
                    type="submit"
                  >
                    Subscribe
                  </button>
                </form>

              )}

            </Formik>
            <p
              className={
                status_message.type === "success"
                  ? "text-left success-color"
                  : "text-left error-color"
              }
            >
              {status_message.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeForm;
