import axios from 'axios';




if (typeof window !== 'undefined') {
  // You now have access to `window`
  if (localStorage.getItem('accessToken')) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
  }
}
axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json';



// Purpose of this,  if the api is down for time being,
//  and  we need to continue our development work


/* AUTH */


export async function REGISTRATION(cred) {
  try {
    let result = await axios.post('/register', cred);

    return result;
  } catch (error) {
    console.error('Error', error);

    return error;
  }
}
export async function profile() {
  try {
    let result = await axios.get('/profile');

    return result;
  } catch (error) {
    console.error('Error', error);

    return error;
  }
}

export async function update_profile(body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try {
    let result = await axios.post('/update_profile', body, config);
    return result;
  } catch (error) {
    return error
  }
}
export async function update_password(body) {
  try {
    let result = await axios.post('/update_password', body);

    return result;
  } catch (error) {
    console.error('Error', error);

    return error;
  }
}

export async function LOGIN(cred) {
  try {
    let result = await axios.post('/login', cred);
    localStorage.setItem("accessToken", result.data.token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;

    return result;
  } catch (error) {
    return error
  }
}

export async function VERIFY_USER_EMAIL(token) {
  try {
    let res = await axios.post('/verify_user_email', { token });
    return res;
  } catch (error) {
    return error
  }
}

export async function FORGOT_PASSWORD(email) {
  try {
    let result = await axios.post('/forgot_password', email);
    return result;
  } catch (error) {
    return error
  }
}
export async function reset_password(body) {
  try {
    let result = await axios.post('/reset_password', body);
    return result;
  } catch (error) {
    return error
  }
}


export async function SUBSCRIPTION(email) {
  try {
    let result = await axios.post('/subscription', email);

    return result;
  } catch (error) {

    console.error('Error', error);
    return error;
  }
}
export async function enquiry(body) {
  try {
    let result = await axios.post('/enquiry', body);

    return result;
  } catch (error) {

    console.error('Error', error);
    return error;
  }
}


//Report API 

export async function reportStore(body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try {
    let result = await axios.post('/reportStore', body, config);
    return result;
  } catch (error) {
    return error
  }
}


export async function GetReports(params, dashboard) {
  let config = null;
  if (dashboard) {
    config = { headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` } };
  } else {
    config = { headers: { 'Authorization': `` } };

  }
  try {
    let result = await axios.get('/getReport' + params, config);
    return result;
  } catch (error) {
    return error
  }
}

export async function getReportDetails(report_id) {
  // axios.defaults.headers.common['Authorization'] = ``;
  try {
    let result = await axios.get('/getReportDetails?report_id=' + report_id);
    return result;
  } catch (error) {
    return error
  }
}


export async function getReportTypeList() {
  try {
    let result = await axios.get('/getReportTypeList');
    return result;
  } catch (error) {
    return error
  }
}


export async function getReportTagList() {
  try {
    let result = await axios.get('/getReportTagList');
    return result;
  } catch (error) {
    return error
  }
}
export async function getSubCategoryList() {
  try {
    let result = await axios.get('/getSubCategoryList');
    return result;
  } catch (error) {
    return error
  }
}




//News API 


export async function GetNews(params) {
  try {
    let result = await axios.get('/getNews' + params);
    return result;
  } catch (error) {
    return error
  }
}

//Events API


export async function GetEvents(params) {
  try {
    let result = await axios.get('/getEvents' + params);
    return result;
  } catch (error) {
    return error
  }
}


export async function getCountryList() {
  try {
    let result = await axios.get('/getCountryList');
    return result;
  } catch (error) {
    return error
  }
}
export async function getNewsCategoryList() {
  try {
    let result = await axios.get('/getNewsCategoryList');
    return result;
  } catch (error) {
    return error
  }
}


export async function calculate(body) {
  let result = await axios.post('/calculate', body);
  return result;
  // try {
  //   return result;
  // } catch (error) {
  //   return error
  // }
}
