import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* eslint-disable jsx-a11y/anchor-is-valid */
import newsImg1 from "../../../assets/images/newsImg1.jpg";
// import newsImg2 from "../../../assets/images/newsImg2.jpg";
// import newsImg3 from "../../../assets/images/newsImg3.jpg";
// import newsImg4 from "../../../assets/images/newsImg4.jpg";
import PaginationComp from "../../common/Pagination";
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { GetNews, getNewsCategoryList } from "../../../request/api";
import { useEffect, useState } from 'react';
import NewsShare from './NewsShare';
import PersonIcon from '@mui/icons-material/Person';
import { LoadingComponent, } from '../../common/CommonComponents';





function NewsCardContent() {
  const initialFilterObject = {
    per_page: 9,
    page: 1,
    category: 0,
  };

  const [filterObject, setFilterObject] = useState(initialFilterObject);
  const [loading, setloading] = React.useState(false)
  const [state, setState] = React.useState({
    CategoryList: []
  })
  const [news, setNews] = useState([]);
  const [tagList, settagList] = React.useState([])
  const [response, setresponse] = React.useState({
    current_page: 1,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: '',
    per_page: 9,
    prev_page_url: null,
    to: 0,
    total: 0,
  });



  const initFilter = async (filterObject) => {
    let queryStringArr = []


    queryStringArr.push(`per_page=${filterObject.per_page}`);
    queryStringArr.push(`page=${filterObject.page}`);


    if (filterObject.category) {
      queryStringArr.push(`category=${filterObject.category}`);
    }



    let queryString = '?' + queryStringArr.join("&");
    if (filterObject !== initialFilterObject) {
      queryString = queryString === "?" ? "" : queryString;

      setloading(true);
      let res = await GetNews(queryString);
      if (res) {
        setresponse(res.data.data);
        setFilterObject(prev => ({ ...prev, page: res.data.data.current_page }));
      }
      setloading(false);
    }
  }


  const handleChangeTag = (v) => {
    let prevTag = [...filterObject.tag];
    let index = prevTag.indexOf(v);
    if (index === -1) {
      prevTag.push(v)
    } else {
      prevTag.splice(index, 1);
    }


    setFilterObject({
      ...filterObject,
      page: 1,
      tag: prevTag
    });
  };

  const handleClick = (cat) => {

    setFilterObject(prev => {
      if (prev.category === cat.id) {
        return { ...prev, page: 1, category: 0 }
      } else {
        return { ...prev, page: 1, category: cat.id }
      }

    });
  }


  useEffect(() => {
    initFilter(filterObject)
  }, [
    filterObject.per_page,
    filterObject.page,
    filterObject.category,
  ]);

  const getAllNews = async () => {
    setloading(true)

    let queryStringArr = []


    queryStringArr.push(`per_page=${filterObject.per_page}`);
    queryStringArr.push(`page=${filterObject.page}`);




    let queryString = '?' + queryStringArr.join("&");

    await GetNews(queryString).then((res) => {

      setresponse(res.data.data);
    });
    setloading(false)
  }

  useEffect(() => {
    getAllNews();
    (async () => {
      let res = await getNewsCategoryList();
      if (res) {

        setState(prev => ({
          ...prev,
          CategoryList: res.data.data
        }))
      }

    })()
  }, []);


  const { CategoryList } = state;


  return (
    <div className="newsCard container">
      <h2 className="pb-2 text-center newsCard__title">News</h2>

      <div className="row">

        <div className="col-md-9 col-12">
          {/*<Tags 
			list={tagList}
            values={filterObject.tag}
            handleChange={handleChangeTag}/>*/}

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            columns={{ xs: 4, sm: 12, md: 12 }}
          >
            <Stack direction="row" spacing={1} mb="20px" alignItems="baseline" flexWrap="wrap">
              <Typography>Tags:</Typography>
              <TagButton

                label={"All"}
                variant="contained"
                className={filterObject.category === 0 ? "selected" : ""}
                onClick={() => handleClick({ id: 0, name: "all" })}
              />
              {CategoryList.map(cat => <TagButton
                key={cat.id}
                label={cat.name}
                variant="contained"
                className={filterObject.category === parseInt(cat.id) ? "selected" : ""}
                onClick={() => handleClick(cat)}
              />)}


            </Stack>
          </Grid>

          {loading ? <LoadingComponent /> :
            <div className="row">
              {response.data.map((n, id) => (
                <div className="col-md-4 col-12 newsCard__col" key={n.id}>

                  <div className="card newsCard__card shadow-sm border">

                    <div className="newsCard__imgWrapper">
                      <a href={n.external_links} className="text-dark" target="_blank" rel="noreferrer">
                        <img
                          src={n.file_path}
                          className="card-img-top newsCard__img"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="card-body newsCard__cardBody">
                      <a href={n.external_links} className="text-dark" target="_blank" rel="noreferrer">
                        <p className="card-text newsCard__cardTitle">
                          {n.title}
                        </p>
                      </a>
                      <div className="newsCard__cardShare">
                        {/* <span> <PersonIcon /> <span className="newsCard__authorName">John Doe</span></span> */}
                        <NewsShare
                          external_links={n.external_links}
                        />
                      </div>
                      {/*<a href="" target="_blank" className="btn btn-outline-primary newsCard__btn">Read More</a>*/}
                    </div>

                  </div>

                </div>
              ))
              }
              {response.data.length === 0 && <h4 className="mt-xs-32 text-center">No result found</h4>}


              {response.total > 9 && <Box sx={{ mt: 4 }}>
                <PaginationComp
                  onChange={(e, page) => {

                    setFilterObject({
                      ...filterObject,
                      // category: 0,
                      page
                    });

                  }}
                  page={filterObject.page}
                  pageCount={Math.ceil(response.total / response.per_page)}

                />
              </Box>}

            </div>

          }

        </div>
        <div className="col-md-3 col-12 newsCard__feedCol shadow border rounded">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="H2Ships"
            options={{ height: 1100 }}
          />

        </div>
      </div>
    </div>
  );
}

export default NewsCardContent;


export const TagButton = styled(Chip)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: "#EDEDF0",
  borderRadius: 22,
  marginRight: 12,
  marginBottom: "16px!important",
  boxShadow: "none",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#74AAF1",
  },
  "&.selected": {
    color: "#fff",
    backgroundColor: "#0B4DA2;",
  },
}));