import NewsCardContent from "../components/contents/newsContent/NewsCardContent";
import SubscribeContent from "../components/contents/homeContent/SubscribeContent";
import EventContainer from '../components/contents/eventContent/EventContainer';


function News() {
  return (
    <>
      <NewsCardContent />
	  <EventContainer />
      <SubscribeContent />
    </>
  );
}

export default News;
