import * as yup from "yup";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import { Formik } from "formik";
import { SUBSCRIPTION } from '../../../request/api';


  
function SubscribeContent(props) {

	let [state, setState] = useState({
		status_message: {
			type: "success",
			message: "",
		},	
	});

	let { status_message } = state;
	

	const subscribe_email_yup = yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required");

	
	const subscribe_init_value = {
		email: "",
	}

	const subscribe_validation_schema = yup.object({
		 email: subscribe_email_yup,
	});

	
	
  return (
    <div className="subscribeContent text-center shadow-sm">
		<div className="container">
			<div className="row justify-content-center align-items-center">
				<div className="col-xl-9 mx-auto">
					<h2 className="text-white mb-4"> Stay Informed of newsletters & publications </h2>
				</div>
    			  <Formik
					enableReinitialize={true}
					initialValues={subscribe_init_value}
					validationSchema={subscribe_validation_schema}
					
					onSubmit = { async(values, actions) => {
						let res = await SUBSCRIPTION(values);
						if (res.data) {
						setState({
							...state,
							
							status_message: {
							type: "success",
							message: res.data.message,
							
							},
						});
					} else {
						let { message, errors } = res.response.data;
						

						for (const property in errors) {
							console.log(`${property}: ${errors[property]}`);
							// message = message + ` ${property}: ${errors[property]}`
							message = message + ` ${errors[property]}`;
						}

						setState({
							...state,
							
							status_message: {
									type: "error",
									message,
								},
							});
						}
					}}
					>	
				
					{(props) => (
					<form onSubmit={props.handleSubmit}>
						<div className="col-md-10 col-lg-8 mx-auto">
						<div className="subscribeContent__form-row">
							<div className="subscribeContent__form-content-1">
							<div className="position-relative">
							<Box sx={{ 
								direction: { xs: 'column', sm: "column", lg: 'column', xl: 'column' },
								display: { xs: 'inline', sm: "inline", lg: 'flex', xl: 'flex' },
								}}>
								
								<Box
								sx={{
									width: { xs: '350px', sm: "350px", lg: '400px', xl: '400px' },
									maxWidth: '100%',
									input: { color: '#222' }
								}}
								>

								<TextField 
								fullWidth 
								label="Enter Your Email" 
								id="email" 
								variant="filled" 
								sx={{ background: "#fff" }}
								name="email"
								value={props.values.email}
								onChange={props.handleChange}
								error={
									props.touched.email && Boolean(props.errors.email)
								}
								helperText={props.touched.email && props.errors.email}
								
								/>
								</Box>
									
										{/* <p
										className={
											status_message.type === "success"
											? "text-center text-white position-absolute top-100 translate-top"
											: "text-center text-danger position-absolute top-100 translate-top"
										}
										>
										{status_message.message}
										</p>
									 */}
									
										<Button
										variant="contained" 
										type="submit"
										sx= {{ marginTop: { xs: '15px', sm: "15px", lg: '0', xl: '0' },
										marginLeft: { xs: '0', sm: "0", lg: '15px', xl: '15px' },
											display: { xs: 'inline', sm: "inline", lg: 'inline', xl: 'inline' } ,
											width: { xs: '100%', sm: "100%", lg: 'auto', xl: 'auto' },
											fontSize: { xs: '16px', sm: "16px", lg: '16px', xl: '16px' }  
										}}
										className="subscribeContent__btnSubscribe">
											Subscribe Now
										</Button>
										</Box>
									</div>
								  <p
                    className={
                      status_message.type === "success"
                        ? "text-left success-color"
                        : "text-left error-color"
                    }
                  >
                    {status_message.message}
                  </p>
							</div>
						
						</div>
					</div>
				</form>
				
				)}	
		  
				</Formik>
			</div>
		</div>
	</div>
 
  );
}

export default SubscribeContent;
