import SubscribeForm from "../components/contents/formContent/SubscribeForm";

function Subscribe() {
  return (
    <>
      <SubscribeForm />
    </>
  );
}

export default Subscribe;
